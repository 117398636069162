<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <!-- title and legend -->
        <b-card-title class="mb-50">
          Documents by Plan Owner
        </b-card-title>
        <!--/ title and legend -->

        <!-- datepicker -->
        <div
          v-if="false"
          class="d-flex align-items-center"
        >
          <feather-icon
            icon="CalendarIcon"
            size="16"
          />
          <flat-pickr
            v-model="dataRange"
            :config="{ mode: 'range'}"
            class="form-control flat-picker bg-transparent border-0 shadow-none"
            placeholder="YYYY-MM-DD"
          />
        </div>
        <!-- datepicker -->
      </b-card-header>

      <b-card-body>
        <!-- vue-apex-charts
          v-if="chartData.series.length > 0"
          ref="chartProjectType"
          type="treemap"
          height="600"
          :options="chartData.options"
          :series="chartData.series"
        / -->
        <vue-apex-charts
          v-if="columnChart.series.length > 0"
          ref="chartProjectType"
          type="bar"
          height="450"
          :options="columnChart.options"
          :series="columnChart.series"
        />
      </b-card-body>
    </b-card>

    <b-card no-body>
      <b-table
        responsive
        :items="items"
        :fields="fields"
        :per-page="data.perPage"
        :current-page="data.currentPage"
        class="mb-0"
      >
        <template #cell(id)="data">
          <b-avatar
            size="32"
            class="shadow-lg"
            :src="`/states/${data.item.state_logo}`"
          />
        </template>

        <!-- Optional default data cell scoped slot -->
        <template #cell()="data">
          {{ data.value }}
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              v-if="false"
              class="text-muted"
            >Showing {{ data.meta.from }} to {{ data.meta.to }} of {{ data.meta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="data.currentPage"
              :total-rows="items.length"
              :per-page="data.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { ref, onUnmounted, watch } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import {
  BRow, BCol, BCard, BCardHeader, BCardBody, BCardTitle, BTable, BPagination, BAvatar,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { $themeColors } from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'
import reportStoreModule from '../reportStoreModule'

export default {
  name: 'ReportPlanOwnerByState',
  setup() {
    const toast = useToast()

    const REPORT_STORE_MODULE_NAME = 'app-report'

    // Register module
    if (!store.hasModule(REPORT_STORE_MODULE_NAME)) store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORT_STORE_MODULE_NAME)) store.unregisterModule(REPORT_STORE_MODULE_NAME)
    })

    const dataRange = ref(['2022-09-01', '2022-09-30'])

    // chart
    const chartProjectType = ref(null)
    const chartData = ref({
      series: [],
      options: {
        chart: {
          height: 600,
          type: 'treemap',
        },
        title: {
          text: 'Documents by Plan Owner Treemap',
          align: 'center',
        },
        legend: {
          show: false,
          // fontSize: '14px',
        },
        colors: [
          '#3B93A5',
          '#F7B844',
          '#ADD8C7',
          '#EC3C65',
          '#CDD7B6',
          '#C1F666',
          '#D43F97',
          '#1E5D8C',
          '#421243',
          '#7F94B0',
          '#EF6537',
          '#C0ADDB',
        ],
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false,
          },
        },
        tooltip: { enabled: true },
      },
    })
    const columnChart = ref({
      series: [],
      options: {
        chart: {
          type: 'bar',
          height: 450,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            // columnWidth: '15px',
            // endingShape: 'rounded',
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['transparent'],
        },
        xaxis: {
          categories: null,
        },
        yaxis: {
          title: {
            text: '# of documents',
          },
          logarithmic: false,
        },
        fill: {
          opacity: 1,
        },
        // tooltip: {
        //   y: {
        //     formatter(val) {
        //       return `$ ${val} thousands`
        //     },
        //   },
        // },
      },
    })

    // table
    const items = ref([])
    const fields = ref([
      {
        key: 'plan_owner_name',
        label: 'Plan Owner',
        sortable: true,
      },
      {
        key: 'count',
        label: 'total',
        sortable: true,
      },
    ])
    const data = ref({
      perPage: 10,
      currentPage: 1,
      meta: {},
    })

    const buildChartDataTree = payload => {
      const series = []

      // labels data and get top
      payload.forEach(row => {
        const planOwnerName = row.plan_owner_name ?? '(others)'
        if (!series.find(s => s.x === planOwnerName)) {
          series.push({ x: `${planOwnerName} (${row.count})`, y: row.count })
        }
      })

      // result
      chartData.value.series.push({ data: series })

      return series
    }

    const buildChartData = (payload, max = 5) => {
      const chart = {
        categories: [],
        series: [{
          name: 'Total Documents',
          data: null,
        }],
      }
      let top = []

      // categories
      payload.forEach(row => {
        const planOwnerName = row.plan_owner_name

        if (!chart.categories.find(c => c === planOwnerName)) {
          chart.categories.push(planOwnerName)
        }
      })

      // series
      chart.series[0].data = new Array(chart.categories.length).fill(0)
      payload.forEach(row => {
        chart.series[0].data[chart.categories.findIndex(c => c === row.plan_owner_name)] = row.count
      })

      // result
      columnChart.value.options.xaxis.categories = chart.categories
      columnChart.value.series = chart.series
      return chart
    }

    store
      .dispatch('app-report/fetchReport', { type: 'plan-owner-total' })
      .then(response => {
        items.value = response.data.payload.data
        buildChartData(response.data.payload.data)
      })
      .catch()
      .finally()

    return {
      chartProjectType,
      toast,

      // refs
      dataRange,
      items,
      fields,
      data,
      chartData,
      columnChart,
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BTable,
    BPagination,
    BAvatar,
    VueApexCharts,
    flatPickr,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
