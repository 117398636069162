<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <!-- title and legend -->
        <b-card-title class="mb-50">
          Top 10 Project Types by State
        </b-card-title>
        <!--/ title and legend -->

        <!-- datepicker -->
        <div
          v-if="false"
          class="d-flex align-items-center"
        >
          <feather-icon
            icon="CalendarIcon"
            size="16"
          />
          <flat-pickr
            v-model="dataRange"
            :config="{ mode: 'range'}"
            class="form-control flat-picker bg-transparent border-0 shadow-none"
            placeholder="YYYY-MM-DD"
          />
        </div>
        <!-- datepicker -->
      </b-card-header>

      <b-card-body>
        <chartjs-component-bar-chart
          v-if="chartData.data.labels.length > 0"
          :height="400"
          :data="chartData.data"
          :options="chartData.options"
        />
      </b-card-body>
    </b-card>

    <b-card no-body>
      <b-table
        responsive
        :items="items"
        :fields="fields"
        :per-page="data.perPage"
        :current-page="data.currentPage"
        class="mb-0"
      >
        <template #cell(id)="data">
          <b-avatar
            size="32"
            class="shadow-lg"
            :src="`/states/${data.item.state_logo}`"
          />
        </template>

        <!-- Optional default data cell scoped slot -->
        <template #cell()="data">
          {{ data.value }}
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              v-if="false"
              class="text-muted"
            >Showing {{ data.meta.from }} to {{ data.meta.to }} of {{ data.meta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="data.currentPage"
              :total-rows="items.length"
              :per-page="data.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { ref, onUnmounted, watch } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import {
  BRow, BCol, BCard, BCardHeader, BCardBody, BCardTitle, BTable, BPagination, BAvatar,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { $themeColors } from '@themeConfig'
import ChartjsComponentBarChart from '../-components/ChartjsComponentBarChart.vue'
import reportStoreModule from '../reportStoreModule'

export default {
  name: 'ReportProjectTypeByState',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BTable,
    BPagination,
    BAvatar,
    ChartjsComponentBarChart,
    flatPickr,
  },
  setup() {
    const toast = useToast()

    const REPORT_STORE_MODULE_NAME = 'app-report'

    // Register module
    if (!store.hasModule(REPORT_STORE_MODULE_NAME)) store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORT_STORE_MODULE_NAME)) store.unregisterModule(REPORT_STORE_MODULE_NAME)
    })

    //
    const chartProjectType = ref(null)

    const dataRange = ref(['2022-09-01', '2022-09-30'])
    const items = ref([])
    const fields = ref([
      {
        key: 'id',
        label: '',
      },
      {
        key: 'state_name',
        label: 'State',
        sortable: true,
      },
      {
        key: 'project_type_name',
        label: 'Project Type',
        sortable: true,
      },
      {
        key: 'count',
        label: 'total',
        sortable: true,
      },
    ])
    const data = ref({
      perPage: 10,
      currentPage: 1,
      meta: {},
    })

    // colors
    const chartColors = {
      blueColor: '#2c9aff',
      primaryColorShade: '#836AF9',
      yellowColor: '#ffe800',
      lineChartDanger: '#ff4961',
      successColorShade: '#28dac6',
      greyColor: '#4F5D70',
      warningLightColor: '#FDAC34',
      tooltipShadow: 'rgba(0, 0, 0, 0.25)',
      blueLightColor: '#84D0FF',
      warningColorShade: '#ffe802',
      lineChartPrimary: '#666ee8',
      labelColor: '#6e6b7b',
      infoColorShade: '#299AFF',
      greyLightColor: '#EDF1F4',
      grid_line_color: 'rgba(200, 200, 200, 0.2)',
    }

    const chartData = ref({
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: true,
          position: 'right',
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              display: true,
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              scaleLabel: {
                display: true,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              display: true,
              gridLines: {
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              ticks: {
                // stepSize: 100,
                min: 0,
                // max: 400,
                fontColor: chartColors.labelColor,
              },
            },
          ],
        },
        /*
        animation: {
          onComplete() {
            const chartInstance = this.chart
            const { ctx } = chartInstance
            ctx.textAlign = 'left'
            // ctx.font = '9px Open Sans'
            ctx.font = '9px'
            ctx.fillStyle = '#fff'

            // draw total count
            this.data.datasets[0].data.forEach(function (data, index) {
              const total = data + this.data.datasets[0].data[index]
              const meta = chartInstance.controller.getDatasetMeta(1)
              const posX = meta.data[index]._model.x
              const posY = meta.data[index]._model.y

              ctx.fillStyle = 'black'
              ctx.fillText(total, posX + 4, posY + 4)
            }, this)
          },
        },
        */
      },
    })

    const buildChartData = (payload, max = 5) => {
      const generateLightColorHex = () => {
        let color = '#'
        for (let i = 0; i < 3; i++) color += (`0${Math.floor(((1 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)}`).slice(-2)
        return color
      }
      const generateDarkColorHex = () => {
        let color = '#'
        for (let i = 0; i < 3; i++) color += (`0${Math.floor(Math.random() * Math.pow(16, 2) / 2).toString(16)}`).slice(-2)
        return color
      }
      const generateRandomColorHex = () => `#${(`00000${Math.floor(Math.random() * Math.pow(16, 6)).toString(16)}`).slice(-6)}`
      const chart = {
        labels: [],
        datasets: [],
      }
      let top = []

      // labels data and get top
      payload.forEach(row => {
        if (!chart.labels.find(c => c === row.state_name)) {
          chart.labels.push(row.state_name ?? '(others)')
        }

        const idx = top.find(t => t.name == row.project_type_name)
        if (idx) {
          top.count += row.count
        } else {
          top.push({ name: row.project_type_name, count: row.count })
        }
      })
      top = top.sort((a, b) => b.count - a.count).slice(0, max).map(i => i.name)

      payload.forEach(row => {
        if (!chart.datasets.find(s => s.label === row.project_type_name ?? '(others)') && top.includes(row.project_type_name)) {
          chart.datasets.push({
            label: row.project_type_name ?? '(others)',
            // data: Array.from({ length: chart.labels.length }, () => Math.floor(Math.random() * 401)),
            data: new Array(chart.labels.length).fill(0),
            // backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
            backgroundColor: chartColors[Object.keys(chartColors)[chart.datasets.length]],
            borderColor: 'transparent',
          })
        }
      })
      chart.datasets.push({
        label: '(others)',
        data: new Array(chart.labels.length).fill(0),
        // backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
        backgroundColor: chartColors[Object.keys(chartColors)[chart.datasets.length]],
        borderColor: 'transparent',
      })

      payload.forEach(row => {
        const labelIndex = chart.labels.findIndex(c => c === row.state_name)

        // update data value
        const dataset = chart.datasets.find(s => s.label === row.project_type_name)
        if (dataset) {
          dataset.data[labelIndex] = row.count
        } else {
          chart.datasets.find(s => s.label === '(others)').data[labelIndex] = row.count
        }
      })

      // result
      chartData.value.data.datasets = chart.datasets
      chartData.value.data.labels = chart.labels

      // chartProjectType.value.updateOptions(chartData.value.options)

      // console.log(chart)
      return chart
    }

    // watch
    // watch(items.value, value => {
    //   const { series, categories } = buildChartData(value)
    //   chartData.value.series = series
    //   chartData.value.options.xaxis.categories = categories
    // },
    // { deep: true })

    store
      .dispatch('app-report/fetchReport', { type: 'project-type-by-state' })
      .then(response => {
        items.value = response.data.payload
        buildChartData(response.data.payload, 10)
      })
      .catch()
      .finally()

    return {
      chartProjectType,
      toast,

      // refs
      dataRange,
      items,
      fields,
      data,
      chartData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
